
// import Data from '@/model/common/Data'
import { defineComponent, ref, watch, PropType } from 'vue'
interface TabItem {
  name: string
  key: string
}
export default defineComponent({
  props: {
    tabList: {
      type: Array as PropType<TabItem[]>,
      default: () => {
        return []
      },
    },
    defaultTab: {
      type: String,
      default: '',
    },
    isSubmitForm: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['tabChange', 'reset', 'submit'],
  setup(props, { emit }) {
    const activeKey = ref()
    console.log(props.isSubmitForm)
    if (props.tabList.length !== 0) {
      activeKey.value = props.defaultTab || props.tabList[0]?.key
      console.log(activeKey.value)
      emit('tabChange', activeKey.value)
    }
    watch(
      () => props.tabList?.length,
      () => {
        activeKey.value = props.defaultTab || props.tabList[0]?.key
        console.log(activeKey.value)
        emit('tabChange', activeKey.value)
      }
    )
    const tabChange = () => {
      emit('tabChange', activeKey.value)
    }
    return {
      activeKey,
      tabChange,
    }
  },
})
