import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cd92d068"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "detailHeader" }
const _hoisted_3 = {
  key: 0,
  class: "card-container"
}
const _hoisted_4 = { class: "detail" }
const _hoisted_5 = { class: "footer" }
const _hoisted_6 = {
  key: 0,
  class: "right"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_a_tabs = _resolveComponent("a-tabs")!
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "detailHeader", {}, undefined, true)
    ]),
    (_ctx.tabList.length !== 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_a_tabs, {
            activeKey: _ctx.activeKey,
            "onUpdate:activeKey": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeKey) = $event)),
            onChange: _ctx.tabChange,
            type: "card"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabList, (item) => {
                return (_openBlock(), _createBlock(_component_a_tab_pane, {
                  key: item?.key,
                  tab: item?.name
                }, null, 8, ["tab"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["activeKey", "onChange"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _renderSlot(_ctx.$slots, "detail", {}, undefined, true)
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_ctx.isSubmitForm)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_a_button, {
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('reset')))
            }, {
              default: _withCtx(() => [
                _createTextVNode("重置")
              ]),
              _: 1
            }),
            _createVNode(_component_a_button, {
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('submit'))),
              type: "primary"
            }, {
              default: _withCtx(() => [
                _createTextVNode("提交")
              ]),
              _: 1
            })
          ]))
        : (_openBlock(), _createBlock(_component_a_button, {
            key: 1,
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$router.go(-1)))
          }, {
            default: _withCtx(() => [
              _createTextVNode("返回")
            ]),
            _: 1
          }))
    ])
  ]))
}